// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetAppSystemsRequestModel from '../../model/get-app-systems-request-model';
import LookupAppSystemsRequestModel from '../../model/lookup-app-systems-request-model';
import GetCustomFieldsRequestModel from '../../model/get-custom-fields-request-model';
import AppSystemListResultModel from '../../model/app-system-list-result-model';
import Int64LookupPagingResultModel from '../../model/int64-lookup-paging-result-model';
import CampaignCustomFieldListResultModel from '../../model/campaign-custom-field-list-result-model';

export default (service: Servicelayer) => ({
  /**  */
  async getAppSystems(request: GetAppSystemsRequestModel, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-app-systems'] ?? 'core/integration/AppSystem';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(AppSystemListResultModel.toModel(dto));
  },
  /**  */
  async lookupAppSystems(request: LookupAppSystemsRequestModel, config?: RequestConfig) {
    const requestDTO = LookupAppSystemsRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-lookup-app-systems'] ?? 'core/integration/AppSystem/Lookup';
    const response = await service.get<any>(endpointPath, {
      query: {
        id: requestDTO.id,
        key: requestDTO.key,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(Int64LookupPagingResultModel.toModel(dto));
  },
  /**  */
  async getCustomFields(request: GetCustomFieldsRequestModel, config?: RequestConfig) {
    const requestDTO = GetCustomFieldsRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-custom-fields'] ?? 'core/integration/AppSystem/{id}/CustomFields';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(CampaignCustomFieldListResultModel.toModel(dto));
  },
});
