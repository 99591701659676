// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetDocumentsRequestModel from '../../model/get-documents-request-model';
import GetDocumentByIdRequestModel from '../../model/get-document-by-id-request-model';
import UploadDocumentRequestModel from '../../model/upload-document-request-model';
import DownloadDocumentRequestModel from '../../model/download-document-request-model';
import DocumentPagingResultModel from '../../model/document-paging-result-model';
import DocumentModel from '../../model/document-model';

export default (service: Servicelayer) => ({
  /**  */
  async getDocuments(request: GetDocumentsRequestModel, config?: RequestConfig) {
    const requestDTO = GetDocumentsRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-documents'] ?? 'core/Documents';
    const response = await service.get<any>(endpointPath, {
      query: {
        fileExtension: requestDTO.fileExtension,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(DocumentPagingResultModel.toModel(dto));
  },
  /**  */
  async getDocumentById(request: GetDocumentByIdRequestModel, config?: RequestConfig) {
    const requestDTO = GetDocumentByIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-document-by-id'] ?? 'core/Documents/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(DocumentModel.toModel(dto));
  },
  /**  */
  async uploadDocument(request: UploadDocumentRequestModel, config?: RequestConfig) {
    const requestDTO = UploadDocumentRequestModel.toDTO(request);
    const formData = new FormData();
    formData.append('uploadFile', requestDTO.uploadFile as Blob);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-upload-document'] ?? 'core/Documents/Upload';
    const response = await service.post<any>(endpointPath, {
      body: formData,
      headers: {
      },
      query: {
        documentsPath: requestDTO.documentsPath,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async downloadDocument(request: DownloadDocumentRequestModel, config?: RequestConfig) {
    const requestDTO = DownloadDocumentRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-download-document'] ?? 'core/Documents/{id}/Download';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      responseType: 'blob',
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(new Blob([result], { type: 'application/octet-stream' }));
  },
});
