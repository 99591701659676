// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetMeRequestModel from '../../model/get-me-request-model';
import VerifyRequestModel from '../../model/verify-request-model';
import ResendVerificationCodeRequestModel from '../../model/resend-verification-code-request-model';
import SetPasswordRequestModel from '../../model/set-password-request-model';
import UserModel from '../../model/user-model';

export default (service: Servicelayer) => ({
  /**  */
  async getMe(request: GetMeRequestModel, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-me'] ?? 'core/Me';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(UserModel.toModel(dto));
  },
  /**  */
  async verify(request: VerifyRequestModel, config?: RequestConfig) {
    const requestDTO = VerifyRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-verify'] ?? 'core/Me/Verify';
    const response = await service.post<any>(endpointPath, {
      query: {
        code: requestDTO.code,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async resendVerificationCode(request: ResendVerificationCodeRequestModel, config?: RequestConfig) {
    const requestDTO = ResendVerificationCodeRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-resend-verification-code'] ?? 'core/Me/ResendVerificationCode';
    const response = await service.post<any>(endpointPath, {
      query: {
        email: requestDTO.email,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async setPassword(request: SetPasswordRequestModel, config?: RequestConfig) {
    const requestDTO = SetPasswordRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-set-password'] ?? 'core/Me/SetPassword';
    const response = await service.post<any>(endpointPath, {
      body: {
        email: requestDTO.email,
        oldPassword: requestDTO.oldPassword,
        newPassword: requestDTO.newPassword,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
});
